<template>
  <div class="contactUs">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel height="80px" direction="vertical" arrow="never" indicator-position="none" :interval="5000">
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div @click="shouye">首页</div>
            <div @click="shanpin">产品中心</div>
            <div @click="zixun">资讯动态</div>
            <div @click="zhaoxian">招贤纳士</div>
            <div @click="yuedong">走进跃动</div>
            <div class="active" @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div class="contactUsmain">
          <div>CONTACT US</div>
          <div>联系我们</div>
        </div>
        <div class="relation">
          <div class="lianxi">
            <div class="lianxiinfo">
              <div class="title">联系方式</div>
              <div class="info">
                <img src="../assets/img/phone.png" />
                <div class="infocen">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div class="fontw">023-68636622</div>
                </div>
              </div>
              <div class="info">
                <img src="../assets/img/phone2.png" />
                <div class="infocen">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
              </div>
              <div class="info">
                <img src="../assets/img/qq.png" />
                <div class="infocen">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
              </div>
              <div class="info">
                <img src="../assets/img/dizhi.png" />
                <div class="infocen">
                  <div>公司地址</div>
                  <div class="family">重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
              <!-- <div class="info">
                <img src="../assets/img/phone2.png" />
                <div class="infocen">
                  <div>网站联系人：陈雪松</div>
                  <div>电话：18581293080</div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="ditu">
            <img src="../assets/img/contactUsimg.png" @click="open">
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>
<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "ContactUs",
  components: { footerComponents },
  data() {
    return {};
  },
  mounted() {
  	if(/Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)) {
  		//移动设备打开的网页，缩放页面尺寸
  		/*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
  	   var rate = 0.1;
  		var meta = document.getElementsByTagName('meta');
  		meta['viewport'].setAttribute('content', 'width=device-width,initial-scale='+rate);
  	}
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian() {
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong() {
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi() {
      this.$router.push("/contactUs");
    },
    //点击地图定位打开新页面
    open(){
      window.open("http://api.map.baidu.com/geocoder?address=正联大厦&output=html&src=webapp.baidu.openAPIdemo");
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  margin: 0 auto;
  height: 80px !important;
  line-height: 80px;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover{
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
      :hover {
        cursor: pointer;
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1480px;
  margin: 0 auto;
  padding: 0;
  .contactUsmain {
    height: 620px;
    background-image: url(../assets/img/contactUsback.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding-top: 220px;
    > div:first-child {
      width: 1200px;
      height: 58px;
      font-size: 48px;
      font-family: SF Display;
      font-weight: normal;
      line-height: 58px;
      color: #ffffff;
      margin: 0 auto;
    }
    > div:last-child {
      width: 1200px;
      height: 71px;
      font-size: 48px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 71px;
      color: #ffffff;
      margin: 10px auto 0;
    }
  }
  .relation {
    max-width: 1903px;
    min-width: 1480px;
    height: 580px;
    display: flex;
    margin: 0 auto;
    .lianxi {
      width: 960px;
      height: 580px;
      background-image: url(../assets/img/contactUsback2.png);
      background-size: cover;
      /* 将背景图片等比缩放填满整个容器 */
      background-repeat: no-repeat;
      background-position: center;
      box-sizing: border-box;
      padding-top: 80px;
      .lianxiinfo {
        width: 390px;
        height: 377px;
        margin-left: 360px;
        .title {
          width: 310px;
          height: 54px;
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          line-height: 54px;
          color: #161e2a;
          margin-bottom: 10px;
        }
        .info {
          height: 48px;
          display: flex;
          align-items: center;
          margin-top: 30px;
          img {
            width: 44px;
            height: 44px;
          }
          .infocen {
            margin-left: 15px;
            > div:first-child {
              height: 20px;
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 500;
              line-height: 20px;
              color: #626778;
            }
            > div:last-child {
              height: 24px;
              font-size: 20px;
              font-family: DIN;
              font-weight: 500;
              line-height: 24px;
              color: #161e2a;
              margin-top: 4px;
            }
            .fontw {
              font-weight: 400 !important;
            }
            .family {
              font-size: 18px !important;
              font-family: Source Han Sans SC !important;
            }
          }
        }
      }
    }
    .ditu {
      width: 960px;
      height: 580px;
      background-image: url(../assets/img/contactUsback3.png);
      background-size: cover;
      /* 将背景图片等比缩放填满整个容器 */
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding-top: 190px;
      img{
        width: 160px;
        height: 135px;
        margin-left: 271px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
